import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

import img1 from "../images/gha1.png";


const PortfolioPage = () => (
  <Layout>
    <SEO 
      title="Javier Suzuki | Design & Front-end Development Portfolio - React Website"
      keywords={[`javier`, `suzuki`, `web`, `ui`, `ux`, `design`, `front-end`, `development`, `react`, `website`]}
    />
    <div className="portfolio-header">
      <h1>Get Help Australia</h1>
      <h5>Website Design</h5>
      <p>Design and development of the website for Get Help Australia, built with React and hosted on Netlify.</p>
    </div>
    <div className="portfolio-footer">
      <a className="btn btn-primary" href="https://gethelpaustralia.com" target="_blank" rel="noopener noreferrer">View Project</a>
    </div>
    <div className="portfolio-content">
      <figure className="portfolio-sample">
        {/* <figcaption className="figheading">Design & Development of Marketing Blog</figcaption> */}
        <img src={img1} alt=""/>
      </figure>
    </div>

  </Layout>
)

export default PortfolioPage
